
import { createTheme, ThemeProvider, makeStyles } from '@mui/material/styles';

// 全体のテーマ設定
export const darkTheme = createTheme({
    palette: {
      text: { primary: '#FFFFFF' , secondary:'#32BEC8' },
      primary: {
        main: '#222222',
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: '#32BEC8',
        contrastText: '#222222',
      },
      background: {
        default: '#222222',
        paper:  '#222222',
      },
    },
    components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              border: '2px solid #000000', // 枠線の色
              boxShadow: '0px 0px 4px 2px #32BEC8', // 影の色
            },
          },
        },
      },
});

export let useTheme = darkTheme;

export function ThemeProviderLight(){
    return (
        <ThemeProvider theme={darkTheme}>

        </ThemeProvider>
    )
}

export function ThemeProviderDark({children}:any){
    useTheme = darkTheme;
    return (
        <ThemeProvider theme={useTheme}>
            {children}
        </ThemeProvider>
    )
}

export function ThemeProviderCustom({children}:any){
    return (
        <ThemeProvider theme={useTheme}>
            {children}
        </ThemeProvider>
    )
}
