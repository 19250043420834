import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { LinkName } from "_routes";


export default function App() {

  
  //window.location.href = LinkName.http404.path;

  /*
  let token = (async () => {
    await axios.post(process.env['API_BASEURL_CENTRAL'] + "login", []);

  });
*/
  const [useToken, setToken] = useState({});

  return (
    <div>
      <h1>Nothing Page.</h1>
      <nav
        style={{
          borderBottom: "solid 1px",
          paddingBottom: "1rem",
        }}
      >
        <Link to={LinkName.login.path}>Login</Link>
      </nav>
    </div>
  );
}
