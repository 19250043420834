import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import * as time from "functions/time";
import * as Theme from "templates/components/Theme";


import 'index.css';
import reportWebVitals from './reportWebVitals';

import {
  Link,
  BrowserRouter,
  RouterProvider,
  Routes,
  Route,
} from "react-router-dom";

import { LoggedIn, NotLoggedIn, LinkName } from "_routes";

// トップDomを取得
const rootDom = document.getElementById('root');

// トークンを取得
let token = localStorage.getItem("token");
console.log(token);

if (rootDom) {
  const root = ReactDOM.createRoot(rootDom);
  root.render(
    
    <Theme.ThemeProviderDark>
      <React.StrictMode>
            {/* path URL */}
            {/* element 画面 */}

            {localStorage.getItem("token") ?
              <RouterProvider router={LoggedIn()} />
              :
              <RouterProvider router={NotLoggedIn()} />
            }

      </React.StrictMode >
    </Theme.ThemeProviderDark>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
