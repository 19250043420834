import React from "react";
import { createBrowserRouter } from "react-router-dom";

import Http404 from "Http404";
import NotFound from "Notfound";

import Index from "pages/Index";
import Login from "pages/Login";
import Logout from "pages/Logout";
import OverLimit from "pages/OverLimit";
import Register from "pages/Register";


import Mypage from "pages/mypage/Index";
import DomainDetail from "pages/mypage/[domain]/Index";

export function LoggedIn(){

  return createBrowserRouter([
    routeTop(),
    routeLogin(),
    routeLogout(),

    routeMypage(),
    domainDetail(),


    overLimit(),
    http404(),
    routeRegister(),
    notRoute(),
  
  ]);

}

export function NotLoggedIn(){

  return createBrowserRouter([
    routeLogin(),
    routeRegister(),
    http404(),
    notRoute()
  ]);

}

export const LinkName = {
  'login':routeLogin(),
  'logout':routeLogout(),
  'register':routeRegister(),
  'mypage':routeMypage(),

  
  'domainDetail':domainDetail(),


  'overLimit':overLimit(),
  
  'http404':http404(),
  'notRoute':notRoute(),

};

function routeTop(){
  return { path: "/", element: <Index /> };
}
function routeLogin(){
  return { path: "/Login", element: <Login /> };
}
function routeLogout(){
  return { path: "/Logout", element: <Logout /> };
}
function routeRegister(){
  return { path: "/Register", element: <Register /> };
}
function routeMypage(){
  return { path: "/Mypage", element: <Mypage /> };
}


// ドメイン詳細ページ
function domainDetail(){
  return { path: "/:domain/", element: <DomainDetail /> };
}


function http404(){
  return { 
    path: "http404", 
    element: <Http404 />
  };
}

function overLimit(){
  return { 
    path: "/overlimit", 
    element: <OverLimit />
  };
}
function notRoute(){
  return { 
    path: "*", 
    element: <NotFound />
  };
}
