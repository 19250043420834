import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import axios from "axios";
import * as axiosCustom from "functions/axios";
import * as env from "functions/env";
import * as time from "functions/time";
import { LinkName } from "_routes";
import LoginFrame from "templates/LoginFrame";


import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Main() {

    const navigate = useNavigate();

    let axiosIns: any;
    axiosIns = axios.create();

    const [useMessage, setMessage] = useState("Please Sign in!");
    const [useAxios, setAxios] = useState("");

    const [useEmail, setEmail] = useState(env.API_LOGIN_USER());
    const emailChange = (e: any) => setEmail(e.target.value);
    const [usePass, setPass] = useState(env.API_LOGIN_PASS());
    const passChange = (e: any) => setPass(e.target.value);

    localStorage.clear();
    const getToken = () => {
        setMessage("stay, authentication");

        const data = {
            username: useEmail,
            password: usePass
        };

        localStorage.setItem('username', useEmail);
        localStorage.setItem('password', usePass);
        axiosCustom.login(data, function (res: any) {
            try {
                console.log('callback');
                if ('status' in res && res.status == 200) {
                    // 成功でtokenをローカルストレージに
                    localStorage.setItem('token', res.data.token);
                    localStorage.setItem('username', useEmail);
                    window.location.href = LinkName.mypage.path;
                    //navigate(LinkName.mypage.path);
                } else {
                    setMessage("misstake");
                    console.log(res);
                }
            } catch (error) {
                console.log(error);
            }
        });

        /*
        axiosIns.get(axiosIns.API_URL.sanctum).then((response: any) => {
            axiosIns.post("/api/login", {
                auth: data
            }).then((res: any) => {
                console.log(res);
                localStorage.setItem('token', res.data.token);
                window.location.href = LinkName.mypage.path;
                //navigate(LinkName.mypage.path);
            }).catch((res: any) => {
                console.log(res);
            });
        });

        
        localStorage.setItem('pass', usePass);
        axiosIns.get('http://localhost:8101/sanctum/csrf-cookie').then((response:any) => {
            axiosIns.post("http://localhost:8101/api/login", {
                auth: data
            }).then((res: any) => {
                console.log(res.data);
                localStorage.setItem('id', res['data']['email']);
                localStorage.setItem('limit', time.getAddOneDay());
                localStorage.setItem('token', res['data']['token']);
                //window.location.href = LinkName.mypage.path;
                //navigate(LinkName.mypage.path);

                axiosIns.post("http://localhost:8101/api/user", {
                }).then((res: any) => {
                    console.log(res.data);
                });
            });
        });
        */
    };

    return (
        <LoginFrame>
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                defaultValue={useEmail}
                onChange={emailChange}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                defaultValue={usePass}
                onChange={passChange}
            />
            <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
            />
            <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={getToken}
            >
                {useMessage}
            </Button>
            <Grid container>
                <Grid item xs>
                    <Link href="#" variant="body2">
                        Forgot password?
                    </Link>
                </Grid>
                <Grid item>
                    <Link href={LinkName.register.path} variant="body2">
                        {"Don't have an account? Sign Up"}
                    </Link>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs>
                    {useAxios}
                </Grid>
            </Grid>
        </LoginFrame>
    );
}
