import { useEffect, useState } from 'react';
import * as axios from "functions/axios";
import * as env from "functions/env";

//import Grid from '@mui/material/Grid';
import Grid from '@mui/material/Grid'; // Grid version 2
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/FormControl';
import Button from '@mui/material/Button';
//import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import { LoggedIn, NotLoggedIn } from "_routes";

export default function Main() {

    let axiosIns:any;
    axiosIns = axios.create();
    
    const [useName, setName] = useState("addnew");
    const nameChange = (e: any) => setName(e.target.value);
    const [useEmail, setEmail] = useState(env.API_LOGIN_USER());
    const emailChange = (e: any) => setEmail(e.target.value);
    const [usePass, setPass] = useState(env.API_LOGIN_PASS());
    const passChange = (e: any) => setPass(e.target.value);


    const createUser = async () => {

        const data = {
            name:useName,
            email: useEmail,
            password: usePass
        };
        
        return await axiosIns.post(axios.API_URL.register, {
            data: data
        }).catch((err:any) => {
            console.log('err:', err);
        });
    };

    return (

        <Container maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography component="h1" variant="h4">
                    Register
                </Typography>

                <Box component="form" noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="名前"
                        name="name"
                        autoComplete="name"
                        autoFocus
                        value={useName}
                        onChange={nameChange}
                    />

                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="メールアドレス"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={useEmail}
                        onChange={emailChange}
                    />

                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="パスワード"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={usePass}
                        onChange={passChange}
                    />

                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={ async () => { console.log(await createUser()); } }
                    >
                        Register
                    </Button>

                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                パスワードを忘れた
                            </Link>
                        </Grid>

                        <Grid item>
                            <Link href="#" variant="body2">
                                新規登録
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}
