import * as React from 'react';
import * as Theme from "templates/components/Theme";
import { useEffect, useState, Fragment } from 'react';
//import {Link} from 'react-router-dom';
import Link from '@mui/material/Link';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import * as axios from "functions/axios";

export default function Main()
{
    return ""
}


export function DomainListItems(){

    let axiosIns = axios.create();

    const [useData, setData] = useState({});
  
    // 初回レンダリング後と、useした値の更新後に自動で実行
    useEffect(() => {
      let mount = true; //マウントされているか
  
      //console.log("Mypage useEffect");
      //console.log(localStorage.getItem('token'));
  
      if (mount) {
        axiosIns.post(axios.API_URL.domainList, {
            'email': localStorage.getItem('username')
        }).then((res: any) => {
          //console.log(res);
          setData(res.data.domainList)
        }).catch((error: any) => {
          //console.log(error);
        });
      }
  
      return () => { mount = false } //クリーンアップでマウントフラグをfalseに
    }, []);
  
    return (<ListItemMenu useData={useData}></ListItemMenu>);

};

function ListItemMenu({useData}:any){
  return(
  <React.Fragment>
      {Object.values(useData).map((val:any) => {//console.log(val);
          return(
          <ListItemButton href={"/"+val['domain']} key={val['domains_name']}>
              <ListItemIcon>
                  <DashboardIcon color={'secondary'}/>
              </ListItemIcon>
              <ListItemText primary={val['domains_name']} />
          </ListItemButton>
          )
      })}
  </React.Fragment>
  )
};
function AccordionMenu({useData}:any){
  return(
    
    <Fragment>
      <Accordion component="div">
          <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
          >
              <ListItemIcon>
                  <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="domain" />
          </AccordionSummary>
          <AccordionDetails>
          {Object.values(useData).map((val:any) => {//console.log(val);
              return(
              <Link href={"/"+val['domain']} key={val['domains_name']}>
                  <ListItemButton >
                      <ListItemText primary={val['domains_name']} />
                  </ListItemButton>
              </Link>
              )
          })}
          </AccordionDetails>
      </Accordion>
  </Fragment>
  )
};
