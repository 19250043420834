import { NewLifecycle, useEffect, useState } from 'react';
import { useParams} from 'react-router-dom';
import * as axios from "functions/axios";
import {Console_log} from "functions/common";
import MainFrame from "templates/MainFrame";

import { PieChart } from '@mui/x-charts/PieChart';
import { LineChart } from '@mui/x-charts/LineChart';

import Link from '@mui/material/Link';

import { LinkName } from "_routes";

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


type AbcProps = {
  domain: string
}

export default function DomainSuumary() {


  const urlParams = useParams<AbcProps>();

  console.log(urlParams.domain);
  
  let axiosIns = axios.create();


  // 週刊サマリデータを取得
  const [useWeekData, setWeekData] = useState();
  useEffect(() => {
    let mount = true; //マウントされているか
    if (mount) {
      axiosIns.post(axios.API_URL.weekSummary, {
        'email': localStorage.getItem('username')
        ,'domain': urlParams.domain
      }).then((res: any) => {
        setWeekData(res.data);
        Console_log("週刊サマリデータを取得:useWeekData", res.data);
      }).catch((error: any) => {
        Console_log("週刊サマリデータを取得:useWeekData", error);
      });
    }
    return () => { mount = false } //クリーンアップでマウントフラグをfalseに
  }, []);
  
  // ドメイン別イベント件数を取得
  const [useEventCountByDomain, setEventCountByDomain] = useState([]);
  useEffect(() => {
    let mount = true; //マウントされているか
    if (mount) {
      axiosIns.post(axios.API_URL.eventCountByDomain, {
        'email': localStorage.getItem('username')
        ,'domain': urlParams.domain
      }).then((res: any) => {
        Console_log("ドメイン別イベント件数を取得:useEventCountByDomain", res.data);
        setEventCountByDomain(res.data);
      }).catch((error: any) => {
        Console_log("ドメイン別イベント件数を取得:useEventCountByDomain", error);
      });
    }
    return () => { mount = false } //クリーンアップでマウントフラグをfalseに
  }, []);

  // ユーザ件数を取得
  const [useGroupSessionId, setGroupSessionId] = useState([]);
  useEffect(() => {
    let mount = true; //マウントされているか
    if (mount) {
      axiosIns.post(axios.API_URL.userCount, {
        'email': localStorage.getItem('username')
        ,'domain': urlParams.domain
      }).then((res: any) => {
        console.log(res);
        setGroupSessionId(res.data);
        Console_log("ユーザ件数を取得:useGroupSessionId", res.data);
      }).catch((error: any) => {
        Console_log("ユーザ件数を取得:useGroupSessionId", error);
      });
    }
    return () => { mount = false } //クリーンアップでマウントフラグをfalseに
  }, []);

  // ページ別イベント件数を取得
  const [useEventToPage, setEventToPage] = useState([]);
  useEffect(() => {
    let mount = true; //マウントされているか
    if (mount) {
      axiosIns.post(axios.API_URL.eventCountByPage, {
        'email': localStorage.getItem('username')
        ,'domain': urlParams.domain
      }).then((res: any) => {
        setEventToPage(res.data);
        Console_log("ページ別イベント件数を取得:useEventToPage", res.data);
      }).catch((error: any) => {
        Console_log("ページ別イベント件数を取得:useEventToPage", error);
      });
    }
    return () => { mount = false } //クリーンアップでマウントフラグをfalseに
  }, []);

  /*
  axiosIns.get(axios.API_URL.user, {
  }).then((res: any) => {
      console.log(res);
  });
  */

  // ユーザ数
  const [useUserCount, setUserCount] = useState(useGroupSessionId.length);

  // 平均ユーザページ閲覧数
  let sumCount = 0;
  useGroupSessionId.map(function(val, idx, ary){
    sumCount += val['count'];
  });
  const [useAvgUserPageCount, setAvgUserPageCount] = useState(sumCount / useUserCount);

  // 円グラフ：ページアタリの閲覧数
  let pieDataHrefCount:any = [];
  let tmp = {};
  useEventToPage.map(function(val, idx, ary){
    tmp = {id: idx, value: val['loadCount'], label: val['href']};
    pieDataHrefCount.push(tmp);
  });

  // 折れ線グラフ
  let lineChartX: string[] = [];
  let lineChartDataUser:any = []; // 適切な初期値を設定
  let lineChartDataClick:any = []; // 適切な初期値を設定
  let lineChartDataLoad:any = []; // 適切な初期値を設定
  if(useWeekData){
    lineChartX = Object.keys(useWeekData);
    lineChartX.map((value: any, index: number, key: any) => {
      lineChartDataClick.push(useWeekData[value]['clickCount']);
      lineChartDataLoad.push(useWeekData[value]['viewCount']);
      lineChartDataUser.push(useWeekData[value]['userCount']);
    });

    /*
    console.log(lineChartX);
    console.log(lineChartDataClick);
    console.log(lineChartDataLoad);
    console.log(lineChartDataUser);
    */
    /*
    lineChartDataLoad = [1543,1499,1875,1222,1543,1543,1654];
    lineChartDataUser = [1300,1432,1212,1276,1257,1255,1101];
    lineChartDataClick = [890,1022,982,769,589,991,1045];
    */
  }
  return (
    <MainFrame key={urlParams.domain}>
      <Typography
        component="h2"
        color={'secondary'}
      >
        {urlParams.domain}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* 直近のサマリデータ */}
          <Paper>
            { useWeekData &&
            <LineChart
              xAxis={[
                { 
                  id: 'Days',
                  scaleType: 'point',
                  data: lineChartX ,
                }
              ]}
              series={[
                {
                  id: 'ClickCount',
                  label: 'Click Count',
                  data: lineChartDataClick,
                },
                {
                  id: 'ViewCount',
                  label: 'View Count',
                  data: lineChartDataLoad,
                },
                {
                  id: 'UserCount',
                  label: 'User Count',
                  data: lineChartDataUser,
                },
              ]}
              height={600}
            />
            }
          </Paper>
        </Grid>

        <Grid item xs={12}>
          {/* ドメイン別データ */}
          <Paper style={{ width: '100%' }}>
            <TableByDomain
              rows={useEventCountByDomain}
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper style={{ width: '100%' }}>
            <TableByPage
              rows={useEventToPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </MainFrame >
  );
}
  
function TableByPage({rows}:any){

  return(
    <TableContainer component={Paper} key={JSON.stringify(rows)}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>href</TableCell>
            <TableCell align="right">user count</TableCell>
            <TableCell align="right">click count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row:any) => (
            <TableRow
              key={JSON.stringify(row)}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.href}</TableCell>
              <TableCell align="right">{row.loadCount}</TableCell>
              <TableCell align="right">{row.clickCount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function TableByDomain({rows}:any){

  return(
    <TableContainer component={Paper} key={JSON.stringify(rows)}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>label</TableCell>
            <TableCell align="right">count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row:any) => (
            <TableRow
              key={JSON.stringify(row)}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.eventName}</TableCell>
              <TableCell align="right">{row.count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
