import React from 'react';

import Link from '@mui/material/Link';
import { LinkName } from "_routes";

export default function Main() {
    localStorage.clear();

    return (
      <main style={{ padding: "1rem 0" }}>
        <h2>HTTP 404</h2>
        
        <Link href={LinkName['login']['path']} >
          login
        </Link>
      </main>
    );
  }
  