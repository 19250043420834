import { useEffect, useState } from 'react';
import * as axios from "functions/axios";
import MainFrame from "templates/MainFrame";

import Link from '@mui/material/Link';
import { LinkName } from "_routes";

export default function Mypage() {
  
  let axiosIns = axios.create();

  const [useData, setData] = useState({});

  // 初回レンダリング後と、useした値の更新後に自動で実行
  useEffect(() => {
    let mount = true; //マウントされているか

    console.log("Mypage useEffect");
    console.log(localStorage.getItem('token'));

    let data = {'email': localStorage.getItem('username')}

    if (mount) {
      axiosIns.post(axios.API_URL.domainList, {'email': localStorage.getItem('username')}).then((res: any) => {
        console.log(res);
        setData(res.data)
      }).catch((error: any) => {
        console.log(error);
      });
    }

    return () => { mount = false } //クリーンアップでマウントフラグをfalseに
  }, []);

  /*
  axiosIns.get(axios.API_URL.user, {
  }).then((res: any) => {
      console.log(res);
  });
  */

  return (
    <MainFrame key="Mypage">
      <h2>MyPage</h2>
    </MainFrame >
  );
}
  