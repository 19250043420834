import React, { createContext, useState } from "react";
import Axios from "axios";
import * as env from "functions/env";

export const API_URL = {
    'base': env.API_URL_BASE(),
    'sanctum': env.API_URL_BASE() + "/sanctum/csrf-cookie",
    'login': env.API_URL_BASE() + "/api/login",
    'logout': env.API_URL_BASE() + "/api/logout",
    'register': env.API_URL_BASE() + "/api/register",
    'user': env.API_URL_BASE() + "/api/user",
    'domainList': env.API_URL_BASE() + "/api/wdc/domain-list",

    'weekSummary': env.API_URL_BASE() + "/api/wdc/event-data/week-summary",
    'userCount': env.API_URL_BASE() + "/api/wdc/event-data/user-count",
    'eventCountByDomain': env.API_URL_BASE() + "/api/wdc/event-data/event-count/domain",
    'eventCountByPage': env.API_URL_BASE() + "/api/wdc/event-data/event-count/page",
};

export function login(auth: any, callbacker: any) {

    const ins = create();
    ins.get(API_URL.sanctum).then((response: any) => {
        ins.post(API_URL.login, {
            auth: auth
        }).then((res: any) => {
            callbacker(res);
        }).catch((error: any) => {

            /*
            if (error.response) {
                // リクエストが行われ、サーバーは 2xx の範囲から外れるステータスコードで応答しました
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);

                alert("リクエストが行われ、サーバーは 2xx の範囲から外れるステータスコードで応答しました");
                alert( JSON.stringify(error.response) );

            } else if (error.request) {
                // リクエストは行われましたが、応答がありませんでした
                // `error.request` は、ブラウザでは XMLHttpRequest のインスタンスになり、
                // Node.js では http.ClientRequest のインスタンスになります。
                console.log(error.request);
                alert("リクエストは行われましたが、応答がありませんでした");
                alert( JSON.stringify(error.request) );
            } else {
                // エラーをトリガーしたリクエストの設定中に何かが発生しました
                console.log('Error', error.message);
                alert("エラーをトリガーしたリクエストの設定中に何かが発生しました");
                alert( JSON.stringify(error) );
            }
            */
            console.log(error.config);
            /*alert( JSON.stringify(error.toJSON() ) ); */
            callbacker(error);
        });
    });
}

export function logout(callbacker: any | null = null) {
    let result: any;

    const ins = create();
    return ins.get(API_URL.logout, {
    }).then((res: any) => {
        // 成功でlocalstrageを削除
        localStorage.clear();
        result = res;
    }).catch((res: any) => {
        result = res.response;
    }).finally(() => {
        if (callbacker) {
            callbacker(result);
        }
    });
}

export function create() {
    return Axios.create({
        baseURL: API_URL.base,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "https://wdc.addnew.tech",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        xsrfHeaderName: "X-XSRF-TOKEN",
        xsrfCookieName: "XSRF-TOKEN",
        withCredentials: true,
        timeout: 60000,
        maxRedirects: 5,
        proxy: {
            protocol: 'https',
            host: '127.0.0.1',
            port: 8101,
          },
    });
}

export function setToken(token: string = "") {

    return Axios.create({
        baseURL: API_URL.base,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        xsrfHeaderName: "X-XSRF-TOKEN",
        xsrfCookieName: "XSRF-TOKEN",
        withCredentials: true,
    });
}

export async function post(instance: any, url: string, data: Array<string | string>) {

    try {
        // URL
        const response = await instance.post(url, data);
        return response.data;
    } catch (error) {
        return error;
    }
}
